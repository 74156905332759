import React from "react";

import { GTMService, SEOMeta } from "@interactive-investor/onestack-web-shared";

import Layout from "../components/layout";
import UsefulLinks from "../components/UsefulLinks";

class ServerErrorPage extends React.Component {
  componentDidMount() {
    GTMService.pushPageView("50x Not Found", window.location.pathname, "50x");
  }

  render() {
    return (
      <Layout>
        <>
          <SEOMeta
            meta={{
              title: "50x Server Error",
              robots: "noindex, nofollow",
              description: "",
              canonical_url: "",
            }}
            titleSuffix=""
          />
          <Layout>
            <div className="GridRow">
              <div className="GridCell-xs-auto">
                <h1>Sorry, there has been a server error</h1>
                <UsefulLinks />
              </div>
            </div>
          </Layout>
        </>
      </Layout>
    );
  }
}

export default ServerErrorPage;
