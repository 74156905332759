import React from "react";

import { OneSiteLink } from "@interactive-investor/onestack-web-shared";

const UsefulLinks = () => (
  <>
    <h2>Useful links</h2>
    <ul>
      <li>
        <a href="https://www.ii.co.uk" title="ii home" target="_blank">
          Home
        </a>
      </li>
      <li>
        <a
          href="https://secure.ii.co.uk"
          title="Trading Account Log In"
          target="_blank"
        >
          Trading Account Log In
        </a>
      </li>
      <li>
        <OneSiteLink to="/auth/login" title="research log in">
          Research Log In
        </OneSiteLink>
      </li>
      <li>
        <a
          href="https://www.ii.co.uk/stock-market-news"
          title="Stock Market News"
          target="_blank"
        >
          Stock Market News
        </a>
      </li>
    </ul>
  </>
);

export default UsefulLinks;
